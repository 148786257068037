import React from 'react';
import { Button } from "@mui/material";
import { useQuery, gql } from '@apollo/client';
import * as XLSX from 'xlsx';

const GET_SUUNNITTELU_DATA = gql`
  query GetSuunnitteluData($tyomaa: String!) {
    construction_data(where: {tyomaanid: {_eq: $tyomaa}}) {
      tyomaanosoite
      suunnittelu_pituus
      suunnittelu_pihalaatta_purku
      suunnittelu_kaivuutapa
      suunnittelu_is_asfalttia
      suunnittelu_lisatietoja
    }
  }
`;

const LataaSuunnitteluosoitteet = ({ tyomaa }) => {
    const { loading, error, data } = useQuery(GET_SUUNNITTELU_DATA, {
        variables: { tyomaa }
    });

    const getKaivuutapaText = (value) => {
        switch(value) {
            case 1: return 'Saha';
            case 2: return 'Aura';
            case 3: return 'Kaivinkone';
            default: return 'Ei määritelty';
        }
    };

    const getYesNoText = (value) => {
        return value === 1 ? 'Kyllä' : 'Ei';
    };

    const handleExport = () => {
        if (data) {
            // Prepare the data
            const rows = [
                [`Tyomaa: ${tyomaa}`],
                [],
                // Header row
                ['Osoite', 'Pituus (m)', 'Pihalaatta purku', 'Kaivuutapa', 'Asfalttia', 'Lisätietoja'],
                // Data rows
                ...data.construction_data.map(item => [
                    item.tyomaanosoite,
                    item.suunnittelu_pituus || '',
                    getYesNoText(item.suunnittelu_pihalaatta_purku),
                    getKaivuutapaText(item.suunnittelu_kaivuutapa),
                    getYesNoText(item.suunnittelu_is_asfalttia),
                    item.suunnittelu_lisatietoja || ''
                ])
            ];

            // Create a worksheet
            const worksheet = XLSX.utils.aoa_to_sheet(rows);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "SuunnitteluTiedot");

            // Write to file
            XLSX.writeFile(workbook, "SuunnitteluTiedot.xlsx");
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <Button onClick={handleExport}>Lataa suunnittelu tiedot</Button>
    );
}

export default LataaSuunnitteluosoitteet;
