import React, {useEffect, useState} from 'react';
import DurationPicker from "./DurationPicker";
import {Alert, Box, Button, InputLabel, MenuItem, Select, Snackbar, TextField, Typography} from "@mui/material";
import {ApolloClient, InMemoryCache, gql, createHttpLink, useQuery} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {useAuth0} from "@auth0/auth0-react";
import AddressComponent from "./AddressComponent";
import projects from "./project";



const EnersenseUudisrakentaminenUpdate = ({ forUpdateData }) => {
    const {user, isAuthenticated, isLoading} = useAuth0();

    const [tyomaaId, setTyomaaId] = useState('');
    const [tyomaanosoite, setTyomaanosoite] = useState('');
    const [tyoAlkoi, setTyoalkoi] = useState(""); // use null for date fields
    const [tyoLoppui, setTyoLoppui] = useState(""); // use null for date fields
    const [kaytettyAika, setKaytettyAika] = useState({ hours: 0, minutes: 0 });
    
    // New suunnittelu fields
    const [suunnitteluPituus, setSuunnitteluPituus] = useState(0);
    const [suunnitteluPihalaattaPurku, setSuunnitteluPihalaattaPurku] = useState(0);
    const [suunnitteluKaivuutapa, setSuunnitteluKaivuutapa] = useState(0);
    const [suunnitteluIsAsfalttia, setSuunnitteluIsAsfalttia] = useState(0);

    const [nimimerkki, setNimimerkki] = useState(user.email);
    const [tyonNimi, setTyonNimi] = useState('EnersenseUudisrakentaminen');
    const [tonttisuunnittelu, setTonttisuunnittelu] = useState(0);
    const [tonttisuunnitteluTime, setTonttisuunnitteluTime] = useState({ hours: 0, minutes: 0 });

    const [sisaAsennus, setSisaAsennus] = useState(0);
    const [sisaAsennusTime, setSisaAsennusTime] = useState({ hours: 0, minutes: 0 });

    const [sisaAsennusLaitteenKanssa, setSisaAsennusLaitteenKanssa] = useState(0);
    const [sisaAsennusLaitteenKanssaTime, setSisaAsennusLaitteenKanssaTime] = useState({ hours: 0, minutes: 0 });

    const [oktTonttisuunnittelu, setOktTonttisuunnittelu] = useState(0);
    const [oktTonttisuunnitteluTime, setOktTonttisuunnitteluTime] = useState({ hours: 0, minutes: 0 });
    const [kuitupuhallusMetria, setKuitupuhallusMetria] = useState(0);
    const [kuitupuhallusMetriaTime, setKuitupuhallusMetriaTime] = useState({ hours: 0, minutes: 0 });
    const [tonttikaivuuMax30Metria, setTonttikaivuuMax30Metria] = useState(0);
    const [tonttikaivuuMax30MetriaTime, setTonttikaivuuMax30MetriaTime] = useState({ hours: 0, minutes: 0 });
    {/*Tonttikaivuu max30 metria = tonttikaivuu metria.*/}
    const [tonttikaivuuYli30Metria, setTonttikaivuuYli30Metria] = useState(0);
    const [jatkamisen_valmistelu, setJatkamisen_valmistelu] = useState(0);
    const [runkokaapelin_kasittely, setRunkokaapelin_kasittely] = useState(0);
    const [tilaajakaapelin_kasittely, setTilaajakaapelin_kasittely] = useState(0);
    const [valokuitujen_kasittely, setValokuitujen_kasittely] = useState(0);
    const [kuitujen_jatkaminen, setKuitujen_jatkaminen] = useState(0);
    const [jatkamisen_valmistelu_time, setJatkamisen_valmisteluTime] = useState({ hours: 0, minutes: 0 });
    const [runkokaapelin_kasittely_time, setRunkokaapelin_kasittelyTime] = useState({ hours: 0, minutes: 0 });
    const [tilaajakaapelin_kasittely_time, setTilaajakaapelin_kasittelyTime] = useState({ hours: 0, minutes: 0 });
    const [valokuitujen_kasittely_time, setValokuitujen_kasittelyTime] = useState({ hours: 0, minutes: 0 });
    const [kuitujen_jatkaminen_time, setKuitujen_jatkaminenTime] = useState({ hours: 0, minutes: 0 });
    const [tonttikaivuuYli30MetriaTime, setTonttikaivuuYli30MetriaTime] = useState({ hours: 0, minutes: 0 });
    const [katukiviLaattaM2, setKatukiviLaattaM2] = useState(0);
    const [katukiviLaattaM2Time, setKatukiviLaattaM2Time] = useState({ hours: 0, minutes: 0 });
    const [kaivinkonetyotunti, setKaivinkonetyotunti] = useState(0);
    const [kaivinkonetyotuntiTime, setKaivinkonetyotuntiTime] = useState({ hours: 0, minutes: 0 });
    const [aputyotunti, setAputyotunti] = useState(0);
    const [aputyotuntiTime, setAputyotuntiTime] = useState({ hours: 0, minutes: 0 });
    const [kuormaAutoTyotunti, setKuormaAutoTyotunti] = useState(0);
    const [kuormaAutoTyotuntiTime, setKuormaAutoTyotuntiTime] = useState({ hours: 0, minutes: 0 });

    const [liittyman_kayttoonotto, setLiittymankayttoonotto] = useState(0);
    const [liittyman_kayttoonotto_time, setLiittymankayttoonotto_time] = useState({ hours: 0, minutes: 0 });

    const [muut, setMuut] = useState('');
    const [muutTime, setMuutTime] = useState({ hours: 0, minutes: 0 });

    const [oktTonttikaivuu, setOktTonttikaivuu] = useState(0);
    const [oktTonttikaivuuTime, setOktTonttikaivuuTime] = useState({ hours: 0, minutes: 0 });
    const [tonttikaivuuMetri, setTonttikaivuuMetri] = useState(0);
    const [tonttikaivuuMetriTime, setTonttikaivuuMetriTime] = useState({ hours: 0, minutes: 0 });
    const [taloyhtioMetriKaivuu, setTaloyhtioMetriKaivuu] = useState(0);
    const [taloyhtioMetriKaivuuTime, setTaloyhtioMetriKaivuuTime] = useState({ hours: 0, minutes: 0 });
    const [tontinulkoinenKaivuuPaallystettyMetria, setTontinulkoinenKaivuuPaallystettyMetria] = useState(0);
    const [tontinulkoinenKaivuuPaallystettyMetriaTime, setTontinulkoinenKaivuuPaallystettyMetriaTime] = useState({ hours: 0, minutes: 0 });
    const [tontinulkoinenKaivuuPaallystamatonMetria, setTontinulkoinenKaivuuPaallystamatonMetria] = useState(0);
    const [tontinulkoinenKaivuuPaallystamatonMetriaTime, setTontinulkoinenKaivuuPaallystamatonMetriaTime] = useState({ hours: 0, minutes: 0 });
    const [tontinulkopuolinenNurmikkoM2, setTontinulkopuolinenNurmikkoM2] = useState(0);
    const [tontinulkopuolinenNurmikkoM2Time, setTontinulkopuolinenNurmikkoM2Time] = useState({ hours: 0, minutes: 0 });
    const [tontinSisainenKaivuuPaallystetty, setTontinSisainenKaivuuPaallystetty] = useState(0);
    const [tontinSisainenKaivuuPaallystettyTime, setTontinSisainenKaivuuPaallystettyTime] = useState({ hours: 0, minutes: 0 });
    const [tontinSisainenKaivuuPaallystamaton, setTontinSisainenKaivuuPaallystamaton] = useState(0);
    const [tontinSisainenKaivuuPaallystamatonTime, setTontinSisainenKaivuuPaallystamatonTime] = useState({ hours: 0, minutes: 0 });
    const [pistemainenKaivuu, setPistemainenKaivuu] = useState(0);
    const [pistemainenKaivuuTime, setPistemainenKaivuuTime] = useState({ hours: 0, minutes: 0 });



    const [page, setPage] = useState(0);
    const [open, setOpen] = useState(false);

    const httpLink = createHttpLink({
        uri: 'https://kuoppapekka.fi/v1/graphql',
    });

    const authLink = setContext((_, { headers }) => {
        const token = localStorage.getItem('accessToken');

        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            }
        }
    });

    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache()
    });

    useEffect(() => {
        if (forUpdateData) {
            setTyomaaId(forUpdateData.tyomaanid || "");
            setTyomaanosoite(forUpdateData.tyomaanosoite || "");
            setTyoalkoi(forUpdateData.tyo_alkoi ? forUpdateData.tyo_alkoi  : "");
            setTyoLoppui(forUpdateData.tyo_loppui ? forUpdateData.tyo_loppui : "");
            setKaytettyAika({ hours: forUpdateData.kaytetty_aika?.split(':')[0] || "0", minutes: forUpdateData.kaytetty_aika?.split(':')[1] || "0" });

            setTonttisuunnittelu(forUpdateData.okt_tonttisuunnittelu || 0);
            setTonttisuunnitteluTime({ hours: forUpdateData.okt_tonttisuunnittelu_time?.split(':')[0] || "0", minutes: forUpdateData.okt_tonttisuunnittelu_time?.split(':')[1] || "0" });


            setSisaAsennus(forUpdateData.sisa_asennus || 0);
            setSisaAsennusTime({ hours: forUpdateData.sisa_asennus_time?.split(':')[0] || "0", minutes: forUpdateData.sisa_asennus_time?.split(':')[1] || "0" });

            setSisaAsennusLaitteenKanssa(forUpdateData.sisa_asennus_laitteen_kanssa || 0);
            setSisaAsennusLaitteenKanssaTime({ hours: forUpdateData.sisa_asennus_laitteen_kanssa_time?.split(':')[0] || "0", minutes: forUpdateData.sisa_asennus_laitteen_kanssa_time?.split(':')[1] || "0" });

            setKuitupuhallusMetria(forUpdateData.kuitupuhallus_metria || 0);
            setKuitupuhallusMetriaTime({ hours: forUpdateData.kuitupuhallus_metria_time?.split(':')[0] || "0", minutes: forUpdateData.kuitupuhallus_ftth_time?.split(':')[1] || "0" });


            setTonttikaivuuMax30Metria(forUpdateData.tonttikaivuu_max30_metria || 0);
            setTonttikaivuuMax30MetriaTime({ hours: forUpdateData.tonttikaivuu_max30_metria_time?.split(':')[0] || "0", minutes: forUpdateData.tonttikaivuu_max30_metria_time?.split(':')[1] || "0" });

            setTonttikaivuuYli30Metria(forUpdateData.tonttikaivuu_yli30_metria || 0);
            setTonttikaivuuYli30MetriaTime({ hours: forUpdateData.tonttikaivuu_yli30_metria_time?.split(':')[0] || "0", minutes: forUpdateData.tonttikaivuu_max30_metria_time?.split(':')[1] || "0" });



            setKatukiviLaattaM2(forUpdateData.katukivi_laatta_m2 || 0);
            setKatukiviLaattaM2Time({ hours: forUpdateData.katukivi_laatta_m2_time?.split(':')[0] || "0", minutes: forUpdateData.katukivi_laatta_m2_time?.split(':')[1] || "0" });


            setKaivinkonetyotunti(forUpdateData.kaivinkonetyotunti || 0);
            setKaivinkonetyotuntiTime({ hours: forUpdateData.kaivinkonetyotunti_time?.split(':')[0] || "0", minutes: forUpdateData.kaivinkonetyotunti_time?.split(':')[1] || "0" });


            setAputyotunti(forUpdateData.aputyotunti || 0);
            setAputyotuntiTime({ hours: forUpdateData.aputyotunti_time?.split(':')[0] || "0", minutes: forUpdateData.aputyotunti_time?.split(':')[1] || "0" });

            setKuormaAutoTyotunti(forUpdateData.kuorma_auto_tyotunti || 0);
            setKuormaAutoTyotuntiTime({ hours: forUpdateData.kuorma_auto_tyotunti_time?.split(':')[0] || "0", minutes: forUpdateData.kuorma_auto_tyotunti_time?.split(':')[1] || "0" });

            setMuut(forUpdateData.muu_tyo || "");
            setMuutTime({ hours: forUpdateData.muu_tyo_time?.split(':')[0] || "0", minutes: forUpdateData.muu_tyo_time?.split(':')[1] || "0" });


            setJatkamisen_valmistelu(forUpdateData.jatkamisen_valmistelu || 0);
            setRunkokaapelin_kasittely(forUpdateData.runkokaapelin_kasittely || 0);
            setTilaajakaapelin_kasittely(forUpdateData.tilaajakaapelin_kasittely || 0);
            setValokuitujen_kasittely(forUpdateData.valokuitujen_kasittely || 0);
            setKuitujen_jatkaminen(forUpdateData.kuitujen_jatkaminen || 0);
            setJatkamisen_valmisteluTime({ hours: forUpdateData.jatkamisen_valmistelu_time?.split(':')[0] || "0", minutes: forUpdateData.jatkamisen_valmistelu_time?.split(':')[1] || "0" });

            setLiittymankayttoonotto(forUpdateData.liittyman_kayttoonotto || 0);
            setLiittymankayttoonotto_time({ hours: forUpdateData.liittyman_kayttoonotto_time?.split(':')[0] || "0", minutes: forUpdateData.liittyman_kayttoonotto_time?.split(':')[1] || "0" });


            setRunkokaapelin_kasittelyTime({ hours: forUpdateData.runkokaapelin_kasittely_time?.split(':')[0] || "0", minutes: forUpdateData.runkokaapelin_kasittely_time?.split(':')[1] || "0" });
            setTilaajakaapelin_kasittelyTime({ hours: forUpdateData.tilaajakaapelin_kasittely_time?.split(':')[0] || "0", minutes: forUpdateData.tilaajakaapelin_kasittely_time?.split(':')[1] || "0" });
            setValokuitujen_kasittelyTime({ hours: forUpdateData.valokuitujen_kasittely_time?.split(':')[0] || "0", minutes: forUpdateData.valokuitujen_kasittely_time?.split(':')[1] || "0" });
            setKuitujen_jatkaminenTime({ hours: forUpdateData.kuitujen_jatkaminen_time?.split(':')[0] || "0", minutes: forUpdateData.kuitujen_jatkaminen_time?.split(':')[1] || "0" });
            
            // Set the new suunnittelu fields
            setSuunnitteluPituus(forUpdateData.suunnittelu_pituus || 0);
            setSuunnitteluPihalaattaPurku(forUpdateData.suunnittelu_pihalaatta_purku || 0);
            setSuunnitteluKaivuutapa(forUpdateData.suunnittelu_kaivuutapa || 0);
            setSuunnitteluIsAsfalttia(forUpdateData.suunnittelu_is_asfalttia || 0);
        }
    }, [forUpdateData]);


    const UPDATE_CONSTRUCTION_DATA = gql`
  mutation UpdateConstructionData(
    $id: Int!,
    $tyomaanid: String,
    $tyomaanosoite: String,
    $tyo_alkoi: date,
    $tyo_loppui: date,
    $kaytetty_aika: String,
    $okt_tonttisuunnittelu: Int,
    $okt_tonttisuunnittelu_time: String,
    $okt_tonttikaivuu: Int,
    $okt_tonttikaivuu_time: String,
    $tonttikaivuu_metri: Int,
    $tonttikaivuu_metri_time: String,
    $sisa_asennus: Int,
    $sisa_asennus_time: String,
    $sisa_asennus_laitteen_kanssa: Int,
    $sisa_asennus_laitteen_kanssa_time: String,
    $kaivinkonetyotunti: Int,
    $kaivinkonetyotunti_time: String,
    $aputyotunti: Int,
    $aputyotunti_time: String,
    $kuorma_auto_tyotunti: Int,
    $kuorma_auto_tyotunti_time: String,
    $taloyhtio_metri_kaivuu: Int,
    $taloyhtio_metri_kaivuu_time: String,
    $tyon_nimi: String,
    $nimimerkki: String,
    $muu_tyo: String,
    $muu_tyo_time: String,
    $kuitupuhallus_metria: Int,
    $kuitupuhallus_metria_time: String,
    $tonttikaivuu_max30_metria: Int,
    $tonttikaivuu_max30_metria_time: String,
    $tonttikaivuu_yli30_metria: Int,
    $tonttikaivuu_yli30_metria_time: String,
    $katukivi_laatta_m2: Int,
    $katukivi_laatta_m2_time: String,
        $jatkamisen_valmistelu: Int,
$jatkamisen_valmistelu_time: String,
$runkokaapelin_kasittely: Int,
$runkokaapelin_kasittely_time: String,
$tilaajakaapelin_kasittely: Int,
$tilaajakaapelin_kasittely_time: String,
$valokuitujen_kasittely: Int,
$valokuitujen_kasittely_time: String,
$kuitujen_jatkaminen: Int,
$kuitujen_jatkaminen_time: String,
$liittyman_kayttoonotto: Int
$liittyman_kayttoonotto_time: String
$suunnitteluPituus: Int
$suunnitteluPihalaattaPurku: Int
$suunnitteluKaivuutapa: Int
$suunnitteluIsAsfalttia: Int
  ) {
    update_construction_data(
      where: { id: { _eq: $id } },
      _set: {
        tyomaanid: $tyomaanid,
        tyomaanosoite: $tyomaanosoite,
        tyo_alkoi: $tyo_alkoi,
        tyo_loppui: $tyo_loppui,
        kaytetty_aika: $kaytetty_aika,
        sisa_asennus: $sisa_asennus,
        sisa_asennus_time: $sisa_asennus_time,
        sisa_asennus_laitteen_kanssa: $sisa_asennus_laitteen_kanssa,
        sisa_asennus_laitteen_kanssa_time: $sisa_asennus_laitteen_kanssa_time,
        okt_tonttisuunnittelu: $okt_tonttisuunnittelu,
        okt_tonttisuunnittelu_time: $okt_tonttisuunnittelu_time,
        okt_tonttikaivuu: $okt_tonttikaivuu,
        okt_tonttikaivuu_time: $okt_tonttikaivuu_time,
        tonttikaivuu_metri: $tonttikaivuu_metri,
        tonttikaivuu_metri_time: $tonttikaivuu_metri_time,
        kaivinkonetyotunti: $kaivinkonetyotunti,
        kaivinkonetyotunti_time: $kaivinkonetyotunti_time,
        aputyotunti: $aputyotunti,
        aputyotunti_time: $aputyotunti_time,
        kuorma_auto_tyotunti: $kuorma_auto_tyotunti,
        kuorma_auto_tyotunti_time: $kuorma_auto_tyotunti_time,
        taloyhtio_metri_kaivuu: $taloyhtio_metri_kaivuu,
        taloyhtio_metri_kaivuu_time: $taloyhtio_metri_kaivuu_time,
        tyon_nimi: $tyon_nimi,
        nimimerkki: $nimimerkki,
        muu_tyo: $muu_tyo,
        muu_tyo_time: $muu_tyo_time,
        kuitupuhallus_metria: $kuitupuhallus_metria,
        kuitupuhallus_metria_time: $kuitupuhallus_metria_time,
        tonttikaivuu_max30_metria: $tonttikaivuu_max30_metria,
        tonttikaivuu_max30_metria_time: $tonttikaivuu_max30_metria_time,
        tonttikaivuu_yli30_metria: $tonttikaivuu_yli30_metria,
        tonttikaivuu_yli30_metria_time: $tonttikaivuu_yli30_metria_time,
        katukivi_laatta_m2: $katukivi_laatta_m2,
        katukivi_laatta_m2_time: $katukivi_laatta_m2_time,
        jatkamisen_valmistelu: $jatkamisen_valmistelu,
jatkamisen_valmistelu_time: $jatkamisen_valmistelu_time,
runkokaapelin_kasittely: $runkokaapelin_kasittely,
runkokaapelin_kasittely_time: $runkokaapelin_kasittely_time,
tilaajakaapelin_kasittely: $tilaajakaapelin_kasittely,
tilaajakaapelin_kasittely_time: $tilaajakaapelin_kasittely_time,
valokuitujen_kasittely: $valokuitujen_kasittely,
valokuitujen_kasittely_time: $valokuitujen_kasittely_time,
kuitujen_jatkaminen: $kuitujen_jatkaminen,
kuitujen_jatkaminen_time: $kuitujen_jatkaminen_time,
liittyman_kayttoonotto : $liittyman_kayttoonotto
liittyman_kayttoonotto_time : $liittyman_kayttoonotto_time
suunnittelu_pituus: $suunnitteluPituus
suunnittelu_pihalaatta_purku: $suunnitteluPihalaattaPurku
suunnittelu_kaivuutapa: $suunnitteluKaivuutapa
suunnittelu_is_asfalttia: $suunnitteluIsAsfalttia
      }
    ) {
      affected_rows
    }
  }
`;

    const GET_PROJECTS_AND_ADDRESSES = gql`
query GetProjectsAndAddresses {
  addresses {
    address
    address_id
    project_id
  }
  projects {
    project_name
    project_id
  }
}
`;

    const handleCloseSend = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        window.location.reload();
    };


    const handleSubmit = () => {
        client.mutate({
            mutation: UPDATE_CONSTRUCTION_DATA,
            variables: {
                id: forUpdateData.id,
                tyomaanid: tyomaaId,
                tyomaanosoite: tyomaanosoite,
                tyo_alkoi: tyoAlkoi !== "" ? tyoAlkoi : undefined,
                tyo_loppui: tyoLoppui !== "" ? tyoLoppui : undefined,
                kaytetty_aika: `${kaytettyAika.hours}:${kaytettyAika.minutes}`,
                okt_tonttisuunnittelu: tonttisuunnittelu,
                okt_tonttisuunnittelu_time: `${tonttisuunnitteluTime.hours}:${tonttisuunnittelu.minutes}`,
                sisa_asennus: sisaAsennus,
                sisa_asennus_time: `${sisaAsennusTime.hours}:${sisaAsennusTime.minutes}`,
                sisa_asennus_laitteen_kanssa: sisaAsennusLaitteenKanssa,
                sisa_asennus_laitteen_kanssa_time: `${sisaAsennusLaitteenKanssaTime.hours}:${sisaAsennusLaitteenKanssaTime.minutes}`,
                kuitupuhallus_metria: kuitupuhallusMetria,
                kuitupuhallus_metria_time: `${kuitupuhallusMetriaTime.hours}:${kuitupuhallusMetriaTime.minutes}`,
                tonttikaivuu_max30_metria: tonttikaivuuMax30Metria,
                tonttikaivuu_max30_metria_time: `${tonttikaivuuMax30MetriaTime.hours}:${tonttikaivuuMax30MetriaTime.minutes}`,
                tonttikaivuu_yli30_metria: tonttikaivuuYli30Metria,
                tonttikaivuu_yli30_metria_time: `${tonttikaivuuYli30MetriaTime.hours}:${tonttikaivuuYli30MetriaTime.minutes}`,
                katukivi_laatta_m2: katukiviLaattaM2,
                katukivi_laatta_m2_time: `${katukiviLaattaM2Time.hours}:${katukiviLaattaM2Time.minutes}`,
                kaivinkonetyotunti: kaivinkonetyotunti,
                kaivinkonetyotunti_time: `${kaivinkonetyotuntiTime.hours}:${kaivinkonetyotuntiTime.minutes}`,
                aputyotunti: aputyotunti,
                aputyotunti_time: `${aputyotuntiTime.hours}:${aputyotuntiTime.minutes}`,
                kuorma_auto_tyotunti: kuormaAutoTyotunti,
                kuorma_auto_tyotunti_time: `${kuormaAutoTyotuntiTime.hours}:${kuormaAutoTyotuntiTime.minutes}`,
                tyon_nimi: tyonNimi,
                nimimerkki: nimimerkki,
                jatkamisen_valmistelu: jatkamisen_valmistelu,
                jatkamisen_valmistelu_time: `${jatkamisen_valmistelu_time.hours}:${jatkamisen_valmistelu_time.minutes}`,
                runkokaapelin_kasittely: runkokaapelin_kasittely,
                runkokaapelin_kasittely_time: `${runkokaapelin_kasittely_time.hours}:${runkokaapelin_kasittely_time.minutes}`,
                tilaajakaapelin_kasittely: tilaajakaapelin_kasittely,
                tilaajakaapelin_kasittely_time: `${tilaajakaapelin_kasittely_time.hours}:${tilaajakaapelin_kasittely_time.minutes}`,
                valokuitujen_kasittely: valokuitujen_kasittely,
                valokuitujen_kasittely_time: `${valokuitujen_kasittely_time.hours}:${valokuitujen_kasittely_time.minutes}`,
                kuitujen_jatkaminen: kuitujen_jatkaminen,
                kuitujen_jatkaminen_time: `${kuitujen_jatkaminen_time.hours}:${kuitujen_jatkaminen_time.minutes}`,
                liittyman_kayttoonotto: liittyman_kayttoonotto,
                liittyman_kayttoonotto_time: `${liittyman_kayttoonotto_time.hours}:${liittyman_kayttoonotto_time.minutes}`,
                muu_tyo: muut,
                muu_tyo_time: `${muutTime.hours}:${muutTime.minutes}`,
                suunnitteluPituus: suunnitteluPituus,
                suunnitteluPihalaattaPurku: suunnitteluPihalaattaPurku,
                suunnitteluKaivuutapa: suunnitteluKaivuutapa,
                suunnitteluIsAsfalttia: suunnitteluIsAsfalttia
            },
        }).then(data => {
            setOpen(true);
        }).catch(error => console.error(error));
    };

    const { loading, error, data } = useQuery(GET_PROJECTS_AND_ADDRESSES);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    //   const projectNames = Object.keys(data.projects);


    const projectNames = data.projects.map(project => project.project_name);

    const projectsWithAddresses = data.projects.map(project => ({
        name: project.project_name,
        project_id: project.project_id,  // Include the project_id here
        addresses: data.addresses
            .filter(address => address.project_id === project.project_id)
            .map(addressObj => addressObj.address)
    }));


    const relevantAddresses = projectsWithAddresses.find(project => project.name === tyomaaId)?.addresses || [];





    const formatDuration = (duration) => {
        return `${duration.hours} hours, ${duration.minutes} minutes`;
    };

    if (page === 0) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '100vh',
                    gap: '16px', // Space between the form elements
                    padding: '16px', // Add padding around the form
                }}
            >
                {/* Tämä oli ennen työmaan id */}
                <Typography variant="body1">
                    Projektit
                </Typography>
                <InputLabel id="projektit-label">Valitse projekti</InputLabel>
                <Select
                    style={{ width: '60vw' }}  // Set width as 60% of the viewport width
                    labelId="projektit-label"
                    value={tyomaaId}
                    onChange={(e) => setTyomaaId(e.target.value)}
                >
                    {projectsWithAddresses.map((project, index) => (
                        <MenuItem key={index} value={project.name}>
                            {project.name}
                        </MenuItem>
                    ))}
                </Select>
                <AddressComponent
                    setTyomaanosoite={setTyomaanosoite}
                    tyomaanosoite={tyomaanosoite}
                    projectName={tyomaaId}
                    relevantAddresses={relevantAddresses}
                    projectsWithAddresses={projectsWithAddresses}
                />  <Typography variant="body1">
                    Työ alkoi
                </Typography>
                <TextField
                    type="date"
                    value={tyoAlkoi}
                    onChange={(e) => setTyoalkoi(e.target.value)}
                    required
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Typography variant="body1">
                    Työ loppui
                </Typography>
                <TextField
                    type="date"
                    value={tyoLoppui}
                    onChange={(e) => setTyoLoppui(e.target.value)}
                    required
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Typography variant="body1">
                    Käyetty aika
                </Typography>
                <DurationPicker
                    value={kaytettyAika}
                    onChange={setKaytettyAika}
                    label="Lisää aika"
                />
                <p>{formatDuration(kaytettyAika)}</p>
                <Button
                    variant="contained"
                    onClick={() => setPage(1)}
                    disabled={!tyomaanosoite || tyomaanosoite.trim() === ''}
                >
                    Jatka eteenpäin
                </Button>
            </Box>
        );
    }

    if (open === true) {
        return (
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleCloseSend}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert onClose={handleCloseSend} severity="success" sx={{ width: '100%' }}>
                    Lähetys onnistui!
                </Alert>
            </Snackbar>
        )
    }

    if (page === 1) {
        return (
            <div>
                <Button variant="contained" color="primary" onClick={() => setPage(0)}>
                    Palaa taaksepäin
                </Button>
                <div className={'div-page3'}>
                    <p>Tonttisuunnittelu (kpl)</p>
                    <input type="number" value={tonttisuunnittelu} onChange={(e) => setTonttisuunnittelu(e.target.value)} />
                    <DurationPicker value={tonttisuunnitteluTime} onChange={setTonttisuunnitteluTime} label="Lisää aika" />
                    <p>{formatDuration(tonttisuunnitteluTime)}</p>
                    
                    <p>Pituus (m)</p>
                    <input type="number" value={suunnitteluPituus} onChange={(e) => setSuunnitteluPituus(Number(e.target.value))} />
                    
                    <p>Kaivuutapa</p>
                    <div>
                        <label>
                            <input 
                                type="radio" 
                                name="suunnitteluKaivuutapaChoice" 
                                value="1" 
                                checked={suunnitteluKaivuutapa === 1} 
                                onChange={() => setSuunnitteluKaivuutapa(1)} 
                            /> Saha
                        </label>
                    </div>
                    <div>
                        <label>
                            <input 
                                type="radio" 
                                name="suunnitteluKaivuutapaChoice" 
                                value="2" 
                                checked={suunnitteluKaivuutapa === 2} 
                                onChange={() => setSuunnitteluKaivuutapa(2)} 
                            /> Aura
                        </label>
                    </div>
                    <div>
                        <label>
                            <input 
                                type="radio" 
                                name="suunnitteluKaivuutapaChoice" 
                                value="3" 
                                checked={suunnitteluKaivuutapa === 3} 
                                onChange={() => setSuunnitteluKaivuutapa(3)} 
                            /> Kaivinkone
                        </label>
                    </div>
                    
                    <p>Onko asfalttia?</p>
                    <div>
                        <label>
                            <input 
                                type="radio" 
                                name="suunnitteluIsAsfalttiaChoice" 
                                value="1" 
                                checked={suunnitteluIsAsfalttia === 1} 
                                onChange={() => setSuunnitteluIsAsfalttia(1)} 
                            /> Kyllä
                        </label>
                    </div>
                    <div>
                        <label>
                            <input 
                                type="radio" 
                                name="suunnitteluIsAsfalttiaChoice" 
                                value="0" 
                                checked={suunnitteluIsAsfalttia === 0} 
                                onChange={() => setSuunnitteluIsAsfalttia(0)} 
                            /> Ei
                        </label>
                    </div>
                    
                    <p>Pihalaatta purku?</p>
                    <div>
                        <label>
                            <input 
                                type="radio" 
                                name="suunnitteluPihalaattaPurkuChoice" 
                                value="1" 
                                checked={suunnitteluPihalaattaPurku === 1} 
                                onChange={() => setSuunnitteluPihalaattaPurku(1)} 
                            /> Kyllä
                        </label>
                    </div>
                    <div>
                        <label>
                            <input 
                                type="radio" 
                                name="suunnitteluPihalaattaPurkuChoice" 
                                value="0" 
                                checked={suunnitteluPihalaattaPurku === 0} 
                                onChange={() => setSuunnitteluPihalaattaPurku(0)} 
                            /> Ei
                        </label>
                    </div>
                </div>
                <div className={'div-page3'}>
                    <p>Sisä Asennus (kpl)</p>
                    <input type="number" value={sisaAsennus} onChange={(e) => setSisaAsennus(e.target.value)} />
                    <DurationPicker value={sisaAsennusTime} onChange={setSisaAsennusTime} label="Lisää aika" />
                    <p>{formatDuration(sisaAsennusTime)}</p>
                </div>
                <div className={'div-page3'}>
                    <p>Kuitupuhallus (Metriä)</p>
                    <input type="number" value={kuitupuhallusMetria} onChange={(e) => setKuitupuhallusMetria(e.target.value)} />
                    <DurationPicker value={kuitupuhallusMetriaTime} onChange={setKuitupuhallusMetriaTime} label="Lisää aika" />
                    <p>{formatDuration(kuitupuhallusMetriaTime)}</p>
                </div>
                <div className={'div-page3'}>
                    <p>Tonttikaivuu, pituus Metriä (m)</p>
                    <input type="number" value={tonttikaivuuYli30Metria} onChange={(e) => setTonttikaivuuYli30Metria(e.target.value)} />
                    <DurationPicker value={tonttikaivuuYli30MetriaTime} onChange={setTonttikaivuuYli30MetriaTime} label="Lisää aika" />
                    <p>{formatDuration(tonttikaivuuYli30MetriaTime)}</p>
                </div>
                <div className={'div-page3'}>
                    <p>Katukivi/laatta (M²)</p>
                    <input type="number" value={katukiviLaattaM2} onChange={(e) => setKatukiviLaattaM2(e.target.value)} />
                    <DurationPicker value={katukiviLaattaM2Time} onChange={setKatukiviLaattaM2Time} label="Lisää aika" />
                    <p>{formatDuration(katukiviLaattaM2Time)}</p>
                </div>
                <div className={'div-page3'}>
                    <p>Kaivinkonetyötunti (tuntia)</p>
                    <input type="number" value={kaivinkonetyotunti} onChange={(e) => setKaivinkonetyotunti(e.target.value)} />
                    <DurationPicker value={kaivinkonetyotuntiTime} onChange={setKaivinkonetyotuntiTime} label="Lisää aika" />
                    <p>{formatDuration(kaivinkonetyotuntiTime)}</p>
                </div>
                <div className={'div-page3'}>
                    <p>Aputyötunti (tuntia)</p>
                    <input type="number" value={aputyotunti} onChange={(e) => setAputyotunti(e.target.value)} />
                    <DurationPicker value={aputyotuntiTime} onChange={setAputyotuntiTime} label="Lisää aika" />
                    <p>{formatDuration(aputyotuntiTime)}</p>
                </div>
                <div className={'div-page3'}>
                    <p>Kuorma-auto Työtunti (tuntia)</p>
                    <input type="number" value={kuormaAutoTyotunti} onChange={(e) => setKuormaAutoTyotunti(e.target.value)} />
                    <DurationPicker value={kuormaAutoTyotuntiTime} onChange={setKuormaAutoTyotuntiTime} label="Lisää aika" />
                    <p>{formatDuration(kuormaAutoTyotuntiTime)}</p>
                </div>
                <div className='div-page3'>
                    <p>Jatkamisen tai päättämisen valmistelu ja lopputyöt (kpl)</p>
                    <input type="number" value={jatkamisen_valmistelu} onChange={(e) => setJatkamisen_valmistelu(e.target.value)} />
                    <DurationPicker value={jatkamisen_valmistelu_time} onChange={setJatkamisen_valmisteluTime} label="Lisää aika" />
                    <p>{formatDuration(jatkamisen_valmistelu_time)}</p>
                </div>
                <div className={'div-page3'}>
                    <p>Liittymän käyttöönotto (kpl)</p>
                    <input type="number" value={liittyman_kayttoonotto} onChange={(e) => setLiittymankayttoonotto(e.target.value)} />
                    <DurationPicker value={liittyman_kayttoonotto_time} onChange={setLiittymankayttoonotto_time} label="Lisää aika" />
                    <p>{formatDuration(liittyman_kayttoonotto_time)}</p>
                </div>
                <div className='div-page3'>
                    <p>Runkokaapelin pään käsittely (kpl)</p>
                    <input type="number" value={runkokaapelin_kasittely} onChange={(e) => setRunkokaapelin_kasittely(e.target.value)} />
                    <DurationPicker value={runkokaapelin_kasittely_time} onChange={setRunkokaapelin_kasittelyTime} label="Lisää aika" />
                    <p>{formatDuration(runkokaapelin_kasittely_time)}</p>
                </div>
                <div className='div-page3'>
                    <p>Tilaajakaapelin pään käsittely (kpl)</p>
                    <input type="number" value={tilaajakaapelin_kasittely} onChange={(e) => setTilaajakaapelin_kasittely(e.target.value)} />
                    <DurationPicker value={tilaajakaapelin_kasittely_time} onChange={setTilaajakaapelin_kasittelyTime} label="Lisää aika" />
                    <p>{formatDuration(tilaajakaapelin_kasittely_time)}</p>
                </div>
                <div className='div-page3'>
                    <p>Valokuitujen käsittely (kpl)</p>
                    <input type="number" value={valokuitujen_kasittely} onChange={(e) => setValokuitujen_kasittely(e.target.value)} />
                    <DurationPicker value={valokuitujen_kasittely_time} onChange={setValokuitujen_kasittelyTime} label="Lisää aika" />
                    <p>{formatDuration(valokuitujen_kasittely_time)}</p>
                </div>
                <div className='div-page3'>
                    <p>Kuitujen jatkaminen (kpl)</p>
                    <input type="number" value={kuitujen_jatkaminen} onChange={(e) => setKuitujen_jatkaminen(e.target.value)} />
                    <DurationPicker value={kuitujen_jatkaminen_time} onChange={setKuitujen_jatkaminenTime} label="Lisää aika" />
                    <p>{formatDuration(kuitujen_jatkaminen_time)}</p>
                </div>
                <div className={'div-page3'}>
                    <p>Lisätietoja (max 255 merkkiä)</p>
                    <input type="text" value={muut} onChange={(e) => setMuut(e.target.value)} />
                    <DurationPicker value={muutTime} onChange={setMuutTime} label="Lisää aika" />
                    <p>{formatDuration(muutTime)}</p>
                </div>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </div>
        );
    }


}


export default EnersenseUudisrakentaminenUpdate;